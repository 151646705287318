import React, { useEffect, FunctionComponent } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import { useConfig, useProfile } from '~components/hooks';
import { useOpenModal } from '~components/modals/hooks';
import { ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import ErrorBoundary from '~public/frontend/pages/error/ErrorBoundary';

import Header from './Header';
import Footer from './Footer';

import { Withdraw } from '~components/modals';

const HrefLang: FunctionComponent = () => {
  const {
    i18n: { languages },
  } = useTranslation();
  const getConfig = useConfig();
  const { pathname } = useLocation();

  if (getConfig.seo.language === 'ru') {
    return null;
  }

  return (
    <Helmet>
      <link
        rel="alternate"
        hrefLang="x-default"
        href={`https://${getConfig.seo?.domainRef}/${getConfig.seo.language}${pathname}`}
      />
      {languages.map(locale => (
        <link
          rel="alternate"
          href={`https://${getConfig.seo?.domainRef}/${locale}${pathname}`}
          hrefLang={locale}
          key={locale}
        />
      ))}
    </Helmet>
  );
};

const MainLayout: FunctionComponent = () => {
  const { isActiveModal, onCloseAll } = useOpenModal();
  const {
    i18n: { language },
  } = useTranslation();
  const getConfig = useConfig();
  const getProfile = useProfile();
  const scrollRef = React.useRef(null);
  const location = useLocation();

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    onCloseAll();
  }, [location.pathname]);

  useEffect(() => {
    if (isActiveModal && document.body.querySelector('.box-modal')) {
      document.body.classList.add('overflow');
    } else {
      document.body.classList.remove('overflow');
    }
  }, [isActiveModal]);

  const title =
    _.get(getConfig, `seo.referral.title[${language}]`) ||
    getConfig?.seo?.referral?.title;
  const description =
    _.get(getConfig, `seo.referral.description[${language}]`) ||
    getConfig?.seo?.referral?.description;
  const keywords =
    _.result(getConfig, `seo.referral.keywords[${language}]`) ||
    getConfig?.seo?.referral?.keywords;

  return (
    <ErrorBoundary>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
      </Helmet>
      <HrefLang />
      <div
        className={`page-profile-landing ${
          !getProfile ? '' : 'page-profile-landing-user'
        }`}
      >
        {getProfile ? (
          <>
            <Withdraw />
          </>
        ) : null}
        <div
          data-host={getConfig.hostname}
          data-acitve-modal={String(isActiveModal)}
          className={`main ${!getProfile ? 'not-author' : 'author'}`}
          ref={scrollRef}
        >
          <ToastContainer
            enableMultiContainer={true}
            limit={3}
            draggable={false}
          />

          <Header />

          <section className="wrap">
            <div id="childrenBlock">
              <ErrorBoundary>
                <Outlet />
              </ErrorBoundary>
            </div>
            <Footer />
          </section>
          <div className="clear" />
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default MainLayout;
