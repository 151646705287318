import { createContext } from "react";
import { reachGoal, sendApi, sendTransaction } from "./YaMetrica";

const YaMetricaContext = createContext({
  reachGoal,
  sendApi,
  sendTransaction
});

export const { Provider, Consumer } = YaMetricaContext;
export default YaMetricaContext;
