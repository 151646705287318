import loadable from '@loadable/component';
import { Routes, Route } from 'react-router-dom';
import _ from 'lodash';
import { useProfile } from '~components/hooks';

import MainLayout from './pages/layouts/MainLayout';
import { Loader } from '~public/frontend/components/Loader';
import { config } from '~public/frontend/i18n';

interface PageProps {
  path: string;
}

const Page = loadable<PageProps>(
  async props => import(`./pages/${props.path}`),
  {
    cacheKey: props => props.path,
    fallback: <Loader />,
  },
) as any;

const RenderRoutes = () => {
  const getProfile = useProfile();

  const routers = [
    { path: '/', element: getProfile ? 'views/Cabinet' : 'Home' },
    { path: '/withdrawals', element: getProfile ? 'views/Cabinet' : 'Home' },
    { path: '/payments', element: getProfile ? 'views/Cabinet' : 'Home' },
    { path: '/howworks', element: getProfile ? 'views/Cabinet' : 'Home' },
    { path: '/faq', element: 'views/Faq' },
  ];

  return (
    <Routes>
      <Route element={<MainLayout />}>
        {_.map(routers, route => (
          <Route
            key={route.path}
            path={route.path}
            element={<Page path={route.element} />}
          />
        ))}
        {_.map(config.fallbackLng, lang => {
          return _.map(routers, route => (
            <Route
              key={`/${lang}${route.path}`}
              path={`/${lang}${route.path}`}
              element={<Page path={route.element} />}
            />
          ));
        })}
        <Route path="*" element={<Page path="error/NotFound" />} />
      </Route>
    </Routes>
  );
};

export default RenderRoutes;
