import { FunctionComponent, SyntheticEvent, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useSubscription, gql } from '@apollo/client';
import { TopMenu } from '~components/header';
import { AuthBlock } from '~public/frontend/components/AuthBlock';
import Currency from '~public/frontend/components/Currency';
import { Languages } from '~public/frontend/components/header/Languages';
import { useProfile, useConfig } from '~components/hooks';
import { useYaMetrica } from '~frontend/provider/yametrica';
import { Price } from '~shared/frontend/ui';
import { useModal } from '~components/modals/hooks';

import ErrorBoundary from '~public/frontend/pages/error/ErrorBoundary';

import Logo from '~public/frontend/scss/images/logo-old.svg';
import IconExit from '~public/frontend/scss/images/icon-menu-11.svg?react';
import IconFaq from '~public/frontend/scss/images/icon-menu-12.svg?react';

import { useToggle } from 'react-use';

const WithdrawButton: FunctionComponent = () => {
  const getProfile = useProfile();
  const yametrica = useYaMetrica();
  const { onShow } = useModal('Withdraw');
  const getConfig = useConfig();

  const { t } = useTranslation();

  const onClick = (
    event: SyntheticEvent<HTMLElement>,
    { reachgoal } = event?.currentTarget?.dataset ||
      (event.target as HTMLElement)?.dataset,
  ) => {
    event.preventDefault();

    onShow({ reachgoal });
  };

  return (
    <a
      className="btn-pay-cost"
      href="#"
      data-reachgoal="withdraw_button_header"
      onClick={onClick}
    >
      <div className="icon material-icons">call_received</div>
      <span className="title">{t('ref_request_withdraw')}</span>
      <span className="cost">
        {getConfig.isEU ? (
          <>
            <span>
              <Currency />
              <Price sum={getProfile.referralMoney} />
            </span>
          </>
        ) : (
          <span>
            <Price sum={getProfile.referralMoney} />
            <Currency />
          </span>
        )}
      </span>
    </a>
  );
};

const Header: FunctionComponent = () => {
  const yametrica = useYaMetrica();
  const { t } = useTranslation();
  const getConfig = useConfig();
  const getProfile = useProfile();
  const [mobileIsAcitve, toogleMobile] = useToggle(false);
  const ref = useRef();
  const { onShow } = useModal('Withdraw');

  useSubscription(
    gql`
      subscription updateProfile {
        updateProfile {
          id
          referralMoney
        }
      }
    `,
  );

  const reachGoal = (
    event: SyntheticEvent<HTMLElement>,
    { reachgoal } = event?.currentTarget?.dataset ||
      (event.target as HTMLElement)?.dataset,
  ) => {
    yametrica.reachGoal(reachgoal);
  };

  const onClick = (
    event: SyntheticEvent<HTMLElement>,
    { reachgoal } = event?.currentTarget?.dataset ||
      (event.target as HTMLElement)?.dataset,
  ) => {
    event.preventDefault();

    onShow({ reachgoal });
  };

  return (
    <header className="header header-profile-landing">
      <div className="header-wrap">
        <div className="header-left">
          <a
            className="logo"
            href={`https://${getConfig.seo.domain}`}
            data-audio="3.mp3"
            data-volume="0.15"
          >
            <img src={Logo} alt="logo" width={210} height={180} />
          </a>
        </div>
        <div className="header-center">
          <ErrorBoundary>
            <TopMenu />
          </ErrorBoundary>
        </div>
        <div className="header-right">
          <Languages />
          {getProfile ? (
            <div className="profile">
              <div className="profile-top">
                <Link className="name" to="/">
                  <img src={getProfile.avatar} alt={getProfile.avatar} />
                  <div className="profile-name">
                    {getProfile.userName}
                    <div className="profile-rank">
                      <div className="icon material-icons">
                        {getProfile.promoCodeIncomePercent >= 15
                          ? 'public'
                          : getProfile.promoCodeIncomePercent === 12
                            ? 'shield'
                            : 'military_tech'}
                      </div>
                      <span>
                        {getProfile.promoCodeIncomePercent >= 15
                          ? 'Global'
                          : getProfile.promoCodeIncomePercent === 12
                            ? 'Guardian'
                            : 'Silver'}
                      </span>
                    </div>
                  </div>
                </Link>
                <a className="logout" href="/auth/logout">
                  <div className="icon material-icons">logout</div>
                </a>
              </div>
              <div className="profile-bot">
                <WithdrawButton />
              </div>
            </div>
          ) : (
            <div className="authorization">
              <a
                className="steam"
                href="/auth/steam"
                onClick={reachGoal}
                data-reachgoal="steam_auth"
              >
                <div className="icon"></div>
                <div className="text">
                  <div className="small">{t('Sign in through')}</div>
                  <div className="big">Steam</div>
                </div>
              </a>
              <div className="authorization-social">
                {getConfig.hostname !== 'cscase.com' && (
                  <a
                    className="vk"
                    href="/auth/vk"
                    onClick={reachGoal}
                    data-reachgoal="vk_auth"
                  ></a>
                )}

                {getConfig.hostname === 'cscase.com' && (
                  <a
                    className="google"
                    href="/auth/google"
                    onClick={reachGoal}
                    data-reachgoal="g_auth"
                  ></a>
                )}

                <a
                  className="fb"
                  href="/auth/fb"
                  onClick={reachGoal}
                  data-reachgoal="fb_auth"
                ></a>
              </div>
            </div>
          )}
        </div>
        <div className="landing-mobile">
          {getProfile ? (
            <>
              <div
                className="header-profile"
                data-reachgoal="withdraw_button_header"
                onClick={onClick}
              >
                <div className="header-profile__cost">
                  <div className="header-profile__cost-val">
                    {getProfile?.referralMoney}
                  </div>
                  <div className="header-profile__btn">
                    <div
                      className="icon material-icons"
                      data-icon="call_received"
                    ></div>
                  </div>
                </div>
              </div>
              <div className="header-profile-user">
                <div className="header-profile-user__header">
                  <div className="header-profile-user__ava">
                    <img src={getProfile?.avatar} />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <AuthBlock />
          )}
          <div
            className={`mobile-menu ${mobileIsAcitve && 'active'}`}
            ref={ref}
          >
            <div
              className={`mobile-menu-btn ${mobileIsAcitve && 'active'}`}
              onClick={toogleMobile}
            >
              <svg
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.5 15H37.5V18.0234H10.5V15ZM10.5 25.4766V22.5234H37.5V25.4766H10.5ZM10.5 33V29.9766H37.5V33H10.5Z"
                  fill="#ffc600"
                />
              </svg>
            </div>
            <div className="mobile-menu-nav">
              <div className="header-profile-user__dropdown-items">
                <Link className="header-profile-user__dropdown-item" to="/faq">
                  <IconFaq />
                  <span>{t('Faq')}</span>
                </Link>
                {getProfile && (
                  <a
                    className="header-profile-user__dropdown-item"
                    href="/auth/logout"
                  >
                    <IconExit />
                    <span>{t('logout')}</span>
                  </a>
                )}
              </div>
              <div className="soc-footer">
                <a
                  className="discord"
                  href={getConfig?.seo.discord}
                  rel="noopener noreferrer nofollow"
                  target="_blank"
                ></a>
                {getConfig?.hostname !== 'cscase.com' && (
                  <>
                    <a
                      className="telegram"
                      href={getConfig?.seo.telegram}
                      rel="noopener noreferrer nofollow"
                      target="_blank"
                    ></a>
                    <a
                      className="vk"
                      href={getConfig?.seo.vk}
                      rel="noopener noreferrer nofollow"
                      target="_blank"
                    ></a>
                  </>
                )}
                <a
                  className="instagram"
                  href={getConfig?.seo.instagram}
                  rel="noopener noreferrer nofollow"
                  target="_blank"
                ></a>
                <a
                  className="fb"
                  href={getConfig?.seo.fb}
                  rel="noopener noreferrer nofollow"
                  target="_blank"
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
