import { FunctionComponent } from 'react';
import { useConfig, useProfile } from '~components/hooks';
import _ from 'lodash';
import { useMount } from 'react-use';

export function sendTransaction({ coupon, revenue, price, name, id }) {
  sendApi(true, 'event', 'purchase', {
    transaction_id: id,
    value: revenue,
    currency: 'RUB',
    items: [
      {
        id: `P${id}`,
        name,
        price,
      },
    ],
  });

  window?.dataLayer?.push({
    ecommerce: {
      purchase: {
        actionField: { id, revenue },
        products: [
          {
            name,
            price,
            coupon,
          },
        ],
      },
    },
  });
}

export function reachGoal(...props: any[]) {
  sendApi(false, 'reachGoal', ...props);
  sendApi(true, 'event', ...props);
}

export function sendApi(type: boolean, ...props: any[]) {
  try {
    if (!_.isBoolean(type) || typeof window === 'undefined') {
      throw new Error('Not found type Metric');
    }

    if (type) {
      return window.gtag && window.gtag(...props);
    }

    return window.ym && window.ym(window['yaCounter'], ...props);
  } catch (e) {
    //  console.error(e);
  }
}

const YaMetrica: FunctionComponent = () => {
  const getConfig = useConfig();
  const getProfile = useProfile();

  useMount(() => {
    window['ym'] =
      window['ym'] ||
      function () {
        (window['ym'].a = window['ym'].a || []).push(arguments);
      };
    window['ym']['l'] = new Date();
    window['yaCounter'] = getConfig.seo.yaCounter;

    window['dataLayer'] = window['dataLayer'] || [];

    window['gtag'] = function () {
      window['dataLayer'].push(arguments);
    };

    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://mc.yandex.ru/metrika/tag.js';

    document.body.appendChild(script);

    const scriptGtag = document.createElement('script');
    scriptGtag.async = true;
    scriptGtag.src =
      'https://www.googletagmanager.com/gtag/js?id=' + getConfig.seo.googleId;

    document.body.appendChild(scriptGtag);

    sendApi(false, 'init', {
      id: getConfig.seo.yaCounter,
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
      triggerEvent: true,
      ecommerce: 'dataLayer',
    });

    sendApi(true, 'js', new Date());
    sendApi(true, 'config', getConfig.seo.googleId);

    setUserInfo();
  });

  const setUserInfo = () => {
    if (getProfile) {
      sendApi(false, 'setUserID', String(getProfile.id));
      sendApi(false, 'userParams', {
        //  money: getProfile.money,
        //    isPay: getProfile.isPay,
        userName: getProfile.userName,
        userId: getProfile.userId,
      });
    }
  };

  if (_.includes(getConfig.userAgent, 'Lighthouse')) {
    return null;
  }

  return (
    <noscript>
      <div>
        <img
          src={`https://mc.yandex.ru/watch/${getConfig.seo.yaCounter}`}
          style={{ position: 'absolute', left: '-9999px' }}
        />
      </div>
    </noscript>
  );
};

export default YaMetrica;
