import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { useConfig } from '~components/hooks';
import Logo from '~public/frontend/scss/images/footer-logo.svg';
import IconMenu15 from '~public/frontend/scss/images/icon-menu-15.svg?react';
import IconMenu16 from '~public/frontend/scss/images/icon-menu-16.svg?react';
import IconMenu17 from '~public/frontend/scss/images/icon-menu-17.svg?react';
import IconMenu6 from '~public/frontend/scss/images/icon-menu-6.svg?react';

const Footer = () => {
  const { t } = useTranslation();
  const getConfig = useConfig();

  return (
    <footer className="footer footer-profile-landing">
      <div className="footer-top">
        <div className="header-menu">
          <a className="header-menu__item" href={`https://${getConfig.seo.domain}`}>
            <span className="header-menu__item-icon">
              <IconMenu15 viewBox="0 0 48 48" />
            </span>
            <span className="header-menu__item-name">{t('Home')}</span>
          </a>
          <NavLink to="/" className="header-menu__item">
            <span className="header-menu__item-icon">
              <IconMenu16 viewBox="0 0 48 48" />
            </span>
            <span className="header-menu__item-name">{t('Cabinet')}</span>
          </NavLink>
          <NavLink to="/faq" className="header-menu__item">
            <span className="header-menu__item-icon">
              <IconMenu6 viewBox="0 0 48 48" />
            </span>
            <span className="header-menu__item-name">FAQ</span>
          </NavLink>
          <a className="header-menu__item" href={getConfig?.seo?.adUrl} target="_blank" rel="noreferrer">
            <span className="header-menu__item-icon">
              <IconMenu17 viewBox="0 0 48 48" />
            </span>
            <span className="header-menu__item-name">{t('For advertising')}</span>
          </a> 
        </div>
        <div className="footer-content">
          <div className="footer-top-item">
            <div className="copyright">
              {getConfig.hostname.toUpperCase()} © 2017-
              {dayjs().year()}.
            </div>
            <div className="agree">
              <a href={`https://${getConfig.seo.domain}/agreement`}>
                {t('Terms of use')}
              </a>
              <div>
                <NavLink to="/faq">{t('Faq')}</NavLink>
              </div>
            </div>
            <a
              className="footer-support-mail"
              href={`mailto:sup@${getConfig.seo.domain}`}
            >
              sup@{getConfig.seo.domain}
            </a>
          </div>
          <div className="logo">
            <NavLink to="/">
              <img src={Logo} />
            </NavLink>
          </div>
          <div className="footer-top-item">
            <div className="soc-footer">
              {getConfig.hostname !== 'cscase.com' && (
                <>
                  <a
                    className="telegram"
                    href={getConfig.seo.telegram}
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                  ></a>
                  <a
                    className="vk"
                    href={getConfig.seo.vk}
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                  ></a>
                  <a
                    className="instagram"
                    href={getConfig.seo.instagram}
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                  ></a>
                </>
              )}
              <a
                className="fb"
                href={getConfig.seo.fb}
                rel="noopener noreferrer nofollow"
                target="_blank"
              ></a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
